import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { translations, WEBSOCKET_URL, constants } from "../base.js";
import { getTimeForChat } from "../utils/dates.js";
import { AuthContext } from "../context/authContext.js";
import { ChatApi } from "../api/chatApi.js";
import Spinner from "../components/common/Spinner.jsx";

const ChatPage = () => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState("");

    const [isUnavailable, setIsUnavailable] = useState(false);
    const [errorMessage, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isScroll, setIsScroll] = useState(false);
    const [lastLoadedId, setLastLoadedId] = useState(0);
    const [trigger, setTrigger] = useState(false);
    const [isEndOfList, setIsEndOfList] = useState(false);

    const socketRef = useRef(null);
    const { currentUser } = useContext(AuthContext);
    const messagesEndRef = useRef(null);

    const { getChatMessages } = ChatApi;

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setIsUnavailable(false);

            let result = await getChatMessages(
                constants.DEFAULTS.CHAT_PAGE_SIZE,
                lastLoadedId
            );

            if (!result?.error) {
                if (result.length > 0) {
                    setMessages((prevMessages) => [
                        ...result.reverse(),
                        ...prevMessages,
                    ]);

                    setIsScroll(lastLoadedId == 0);
                }

                // No more records
                setIsEndOfList(
                    result.length < constants.DEFAULTS.CHAT_PAGE_SIZE
                );
            } else {
                setError(result.error);
            }

            setIsLoading(false);
        };

        fetchData();
    }, [lastLoadedId, getChatMessages, trigger]);

    const handleLoadMore = () => {
        setTrigger((prev) => !prev);
        setLastLoadedId(messages?.length > 0 ? messages[0].id : 0);
    };

    useEffect(() => {
        // Connect to WebSocket server
        socketRef.current = new WebSocket(WEBSOCKET_URL);

        // Listen for incoming messages
        socketRef.current.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);

                if (data.type === "newMessage") {
                    setIsScroll(data.data.userId === currentUser.id);
                    setMessages((prevMessages) => [...prevMessages, data.data]); // Add new message
                }
            } catch (error) {
                console.error("Invalid JSON received:", event.data);
            }
        };

        socketRef.current.onopen = () => {
            //console.log("WebSocket connection established");
        };

        socketRef.current.onclose = () => {
            //console.log("WebSocket connection closed");
        };

        // Cleanup on component unmount
        return () => {
            socketRef.current?.close();
        };
    }, []);

    const sendMessage = () => {
        if (
            socketRef.current &&
            socketRef.current.readyState === WebSocket.OPEN &&
            input.trim() &&
            input.trim().length <= constants.VALIDATION.MAX_CHAT_MESSAGE_SIZE
        ) {
            const message = {
                type: "chatMessage",
                userId: currentUser.id,
                nick: currentUser.nick,
                content: input.trim(),
            };

            socketRef.current.send(JSON.stringify(message));
            setInput(""); // Clear input after sending
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            sendMessage();
        }
    };

    // Scroll to the bottom when messages change
    useEffect(() => {
        if (messagesEndRef.current && isScroll) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages]);

    return (
        <div className="two-column-container">
            <div className="chat-content">
                <h1>{translations.Menu.Chat}</h1>
                <div className="chat-welcome">
                    {translations.Chats.BigChatWelcome}
                </div>
                <div className="chat-messages">
                    <div className="actions">
                        {!isLoading && !isEndOfList && (
                            <button onClick={handleLoadMore} className="small">
                                {translations.Buttons.LoadMore}
                            </button>
                        )}
                        {isLoading && <Spinner />}
                    </div>

                    {messages.map((msg, index) => (
                        <div key={index} className="chat-message">
                            <div className="message-info">
                                <div className="date">
                                    [{getTimeForChat(msg.created)}]
                                </div>
                                <div className="nick">
                                    <Link
                                        to={`/user/${msg.userId}`}
                                        target="_blank"
                                    >
                                        {msg.nick}
                                    </Link>
                                </div>
                            </div>
                            <div className="content">{msg.content}</div>
                        </div>
                    ))}
                    <div ref={messagesEndRef} />
                </div>

                {currentUser && (
                    <form>
                        <input
                            type="text"
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                        <button type="button" onClick={sendMessage}>
                            Send
                        </button>
                    </form>
                )}

                {!currentUser && (
                    <div className="center">
                        <Link to="/login">{translations.Auth.LoginToChat}</Link>
                    </div>
                )}

                {isUnavailable && (
                    <div className="error-message-block">
                        {translations.Errors.ServiceIsTemporaryUnavalable}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ChatPage;
