import axios from "axios";
import { constants, translations, SERVICE_URL } from "../base.js";

// Create an axios instance with headers for all requests
const apiClient = axios.create({
    baseURL: SERVICE_URL,
    headers: {
        "x-api-key": constants.API_KEY,
    },
});

export const ChatApi = {
    /**
     * Gets chat messgages
     *
     * @param {number} count
     * @param {number} lastLoadedId
     * @returns {Array} messages
     */
    getChatMessages: async (
        count = constants.DEFAULTS.PAGE_SIZE_NORMAL,
        lastLoadedId = Number.MAX_SAFE_INTEGER
    ) => {
        try {
            const endpointUrl = `${SERVICE_URL}/chat/list/${count}/${lastLoadedId}`;

            const result = await apiClient.get(endpointUrl);

            if (result.data && result.data.length > 0) {
                return result.data;
            }

            return [];
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return [];
    },
};
