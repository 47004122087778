export const translations = {
    SiteName: "RUKME",
    SiteTitle: "RUKME",
    SiteDescription:
        "Добро пожаловать на сайт квн-разминки, где авторы пишут смешные вопросы, смешно на них отвечают и голосоют за самые смешные шутки! Заходы, добивки, шутки, прибаутки - все это у нас есть. RUKME.RU - поиграй в квн-разминку. Улыбать - это даже важнее, чем улыбаться :)",
    Common: {
        User: "Автор",
        Rating: "Веселость",
        ContactsContent:
            "По любым вопросам, связанным с сайтом, обращайтесь по адресу",
    },
    Menu: {
        Home: "RUKME - поиграй в КВН-разминку!",
        Parents: "Заходы",
        Parent: "Заход",
        MyParents: "Мои заходы",
        Children: "Добивки",
        MyChildren: "Мои добивки",
        Favorites: "Избранное",
        Comments: "Комментарии",
        Top: "Топ",
        TopRecent: "Свежий",
        TopAll: "За все время",
        Recent: "Свежие",
        ByRating: "Лучшие",
        Forum: "Форукм",
        Statistics: "Веселая статистика",
        Badges: "Бейджи",
        Contacts: "Контакты",
        Feedbacks: "Отзывы",
        User: "Автор",
        Users: "Авторы",
        About: "О сайте",
        ParentAndChildFrom: "Шутка от",
        ParentAndChild: "Шутка",
        ParentFrom: "Заход от",
        Notifications: "Уведомления",
        Messages: "Сообщения",
        Search: "Поиск",
        News: "Новости",
        Rooms: "Комнатушки",
        RoomParents: "Комнатные заходы",
        RoomChildren: "Комнатные добивки",
        Chat: "Чатик",
    },
    Auth: {
        Enter: "Вход на сайт",
        Login: "Входите",
        Email: "E-mail",
        Nickname: "Зовите меня",
        Password: "Волшебное слово",
        CurrentPassword: "Волшебное слово сейчас",
        NewPassword: "Новое волшебное слово",
        ForgotPassword: "Забыли волшебное слово?",
        ResetPassword: "Восстановить пароль",
        ResetPasswordWarning:
            "После того, как вы отправите эту форму мы вышлем вам ссылку для подтверждения на указанный адрес электронной почты.\nВаш пользователь станет неактивным пока вы не перейдете по ссылке для подтверждения.",
        ResetPasswordConfirmed:
            "Пароль восстановлен, теперь вы можете войти на сайт с новым паролем." /*"Registration confirmed, you can now log in.",*/,
        ResetPasswordRequested:
            "Пройдите по ссылке, которую мы выслали на ваш адрес электронной почты, что подтвердить смену пароля. Ваша учетная запись останется неактивной пока вы не перейдете по ссылке для подтверждения." /*"Registration confirmed, you can now log in.",*/,
        ConfirmResetPassword: "Подтверждение восстановления пароля.",
        ChangePassword: "Сменить волшебное слово?",
        LoginToChangePassword:
            "Войдите на сайт, чтобы сменить волшебное слово.",
        ChangePasswordConfirmed:
            "Пароль изменен, теперь вы можете войти на сайт с новым паролем." /*"Registration confirmed, you can now log in.",*/,
        YouCanRegister: "Может новый аккаунт создать?",
        Logout: "Выйти из себя",
        Register: "Зарегаться",
        UserNameRules:
            '"Зовите меня" должно быть длиной от {1} до {2} символов и содержать только буквы, цифры и пробелы',
        PasswordRules:
            '"Волшебное слово" должно быть длиной от {1} до {2} символов.',
        RegisterConfirmed:
            "Учетная запись активирована, теперь вы можете войти на сайт." /*"Registration confirmed, you can now log in.",*/,
        ConfirmRegistration:
            "Активация учетной записи." /*"Registration confirmed, you can now log in.",*/,
        UserCreated:
            "Учетная запись создана, пройдите по ссылке, которую мы выслали на ваш адрес электронной почты, чтобы ее активировать." /* "Your account has been created, please check your email box and follow the link to activate it.",*/,
        LoginToChat: "Войдите на сайт, чтобы чатиться.",
    },
    User: {
        Location: "Я обитаю",
        Info: "Обо мне",
        IWrote: "Я написал",
        Registered: "Зарегался",
        SendMessage: "Отправить сообщение",
        ContactUser: "Пообщаться",
        FindUser: "Новый чат с...",
    },
    Forms: {
        Submit: "Поехали",
        Send: "Отправить",
        Save: "Сохранить",
        Close: "Закрыть",
        Delete: "Удалить",
        Cancel: "Нет-нет-нет!",
        Find: "Найти",
        AreYouSureDelete: "Вы уверены, что хотите удалить",
        WriteSomething: "Напишите что-нибудь...",
    },
    Buttons: {
        LoadMore: "Загрузить еще",
        OrderByDate: "По дате",
        OrderByRating: "По рейтингу",
        OrderByUser: "По пользователю",
    },
    Errors: {
        GeneralError:
            "Ох... Произошло что-то непредвиденное :(" /*"Oops... Something went wrong :(",*/,
        ServiceIsTemporaryUnavalable:
            "Сервис временно надоступен..." /*"Service is temporary unavalable...",*/,
        ContentTooShort: "Как-то слишком коротко.",
        TitleTooShort: "Как-то слишком коротко заголовке.",
        NameTooShort: "Как-то слишком коротко заголовке.",
        DescriptionTooLong: "Слишком много написали.",
        CurrentPasswordIncorrect: "Текущий пароль не такой!",
        PasswordIncorrect: "Пароль не такой!",
        UserNotFound:
            "Пользователь с таким адресом электронной почты не существует." /*"User with this email doesn't exist.",*/,
        UserInfoNotFound: "Автор не найден",
        LoginUserNotActive:
            "Учетная запись не активирована, проверьте, пожалуйста, свою электонную почту, ссылка на активацию была выслана." /*"User is not active, please check your email box for activation link.",*/,
        ConfirmLinkInvalid:
            "Ссылка на активацию устарела, запросите еще раз или обратитесь к администратору сайта." /*"Activation link is invalid, please contact administrator.",*/,
        RegisterUserExists:
            "Пользователь с таким адресом электронной почты уже зарегистрирован." /*"User with this email or nick already exists.",*/,
        NickExists: "Этот никнэйм уже кем-то используется.",
        InvalidData: "Данные не верны.",
        NotLoggedIn: "Вы еще не вошли в своую учетную запись.",
        RoomAccessDenied: "Это комнатушка вам не доступна.",
    },
    Messages: {
        NoItems: "Пока тут ничего...",
        NoMoreItems: "Больше ничего нет. Пока... :)",
        NoMessages:
            "У вас еще нет сообщений. Перейдите на страницу автора, чтобы написать им что-нибудь.",
        Saved: "Сохранили",
        Me: "я",
    },
    Children: {
        NewChild: "Новая добивка",
        UserChildren: "Добивки автора",
        AlreadyInFavorites: "Добивка уже в избранном",
        Funny: "Смешно",
        VeryFunny: "Оборжака :D",
        Neutral: "Ok",
        ChildNotFound: "Добивка не найдена",
        Child: "добивка",
        Wrote: "Написал",
        WriteChild: "Написать добивку...",
    },
    Parents: {
        NewParent: "Новый заход",
        UserParents: "Заходы автора",
        ParentNotFound: "Заход не найден",
        Recent: "Свежие",
        Random: "Случайные",
        Parent: "заход",
    },
    DateAndTime: {
        Modified: "Изменено",
        JustNow: "только что",
        MinuteAgo: "минута назад",
        MinutesAgo: "минут назад",
        MinutesAgo_2_3_4: "минуты назад",
        HourAgo: "час назад",
        HoursAgo: "часов назад",
        HoursAgo_2_3_4: "часа назад",
        DayAgo: "день назад",
        DaysAgo: "дней назад",
        DaysAgo_2_3_4: "дня назад",
        Yesterday: "вчера",
    },
    Scraps: {
        Wrote: "Написал",
        WriteChild: "Написать добивку...",
        NoItems: "Пока ничего не написали...",
    },
    Badges: {
        USER_OF_THE_WEEK: "Автор недели",
        USER_OF_THE_MONTH: "Автор месяца",
        CHILD_OF_THE_WEEK: "Шутка недели",
        BATTLE_WINNER: "Победитель батла",
        CONTEST_WINNER: "Победитель конкурса",
        DOUGLAS_ADAMS_42: "42",
        VIP: "За заслуги перед Рукмиручеством",
        USER_OF_THE_YEAR: "Автор года",
        CHILD_OF_THE_YEAR: "Шутка года",
        UNKNOWN: "Неизвестный бейдж",
    },
    Widgets: {
        RandomChild: "Случайная шутка",
        UserOfTheWeek: "Автор недели",
        UserOfTheMonth: "Автор месяца",
        UserOfTheYear: "Автор года",
        ChildOfTheWeek: "Шутка недели",
        OnlineUsers: "Кто онлайн",
        NoOneOnline: "Пока никого",
        TopUsers: "Крутаны за все время",
        DynamicRatings: "Топ месяца",
        SeeAll: "Все авторы",
    },
    NotFound: {
        Heading: "404 - Я потерялася",
        Content: "Вы куда-то не туда зашли...",
    },
    News: {
        comments: "комменты",
        NewNews: "Новая новость",
        SingleNews: "Новость",
        NewsNotFound: "Новость не найдена",
        AllNews: "Все новости",
    },
    Rooms: {
        Room: "Комнатушка",
        Rooms: "Комнатушки",
        RoomNotFound: "Комнатушка не найдена",
        AllRooms: "Все комнатушки",
        Participants: "Кто-кто в комнатушке",
        Created: "Создана",
        Public: "Для всех",
        Private: "Приватная",
        Publics: "Общие",
        Privates: "Приватные",
        NewRoom: "Создать",
        AddParticipants: "Добавьте участников в комнатушку...",
        RoomName: "Название комнатушки...",
        RoomDescription: "Что будем тут делать...",
        MakePrivate: "Приватная?",
        RoomLinks: "Ссылки",
    },
    Feedbacks: {
        Feedback: "Дорогая редакция",
        SendFeedback: "Отправить отзыв или предложение",
        ThanksForYourFeedback: "Спасибо за ваш отзыв!",
    },
    Icons: {
        Edit: "Изменить",
        Delete: "Удалить",
        AddToFavorites: "Добавить в избранное",
        RemoveFromFavorites: "Удалить из избранного",
        ParentChildren: "Все добивки захода",
        Write: "Написать добивку",
        Comments: "Комментарии",
        Share: "Поделиться",
        Notifications: "Уведомления ",
        MedalGold: "Золотая медаль",
        MedalSilver: "Серебряная медаль",
        MedalBronze: "Бронзовая медаль",
    },
    Chats: {
        ChatWith: "Переписка с",
        SendMessage: "Написать сообщение...",
        RecentChats: "Последние чаты",
        BigChatWelcome:
            "Это общий чат для всех зарегистрированных пользователей. Добро пожаловать в наш уютный чатик :)",
    },
};
